import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header
          style={{
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `flex-end`,
            alignContent: `flex-end`,
            borderBottom: `1px solid #ccc`,
            marginBottom: `1rem`,
            paddingBottom: `.2rem`,
          }}
        >
          <h1
            style={{
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>

          <div>
            <div
              style={{
                color: `#999`,
                fontStyle: `italic`,
                padding: `0 .4em`,
                fontSize: `.7em`,
              }}
            >
              {post.frontmatter.date}
            </div>
            <div
              style={{
                backgroundColor: `#fff`,
                color: `#89a`,
                textAlign: `right`,
                padding: `0 .4em`,
                fontSize: `.7em`,
                borderTop: `1px dashed #ccc`,
                marginBottom: `-.2rem`,
              }}
            >{post.frontmatter.category}</div>
          </div>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: `1em`,
          }}
        />
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "D MMMM YYYY", locale: "fr")
        description
        category
      }
    }
  }
`
